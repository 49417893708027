import { createSlice } from '@reduxjs/toolkit'

export const porfileSlice = createSlice({
    name: 'error',
    initialState: {
        id: -1,
        userUuid: '',
        email: '',
        firstName: {en: '-', th: '-'},
        lastName: {en: '-', th: '-'},
        photo: '/images/default-user-profile.png',
        company: -1,
        role: 'not-access',
        userPermission : [],
    },
    
    reducers:{
        setProfile: (state, { payload }) => {
            state = {
                ...state,
                ...payload,
            }
            return state
        },
        setUsername: (state, { payload }) => {
            state.firstName = payload.firstName
            state.lastName = payload.lastName
        },
        setEmailAndUuid: (state, {payload}) => {
            state.userUuid = payload.userUuid
            state.email = payload.email
        },
    },
})

export const { 
    setProfile,
    setUsername,
    setEmailAndUuid,
} = porfileSlice.actions

export default porfileSlice.reducer

import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: {},
    },
    reducers:{
        handleLogin: (state, action) => {
            state.userData = action.payload
            localStorage.setItem('userData', JSON.stringify(action.payload))
            localStorage.setItem('isLogin', action.payload.accessToken)
        },
        handleLogout: state => {
            state.userData = {}
            localStorage.clear()
            sessionStorage.clear()
        },
    },
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
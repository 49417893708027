// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
// import auth from './authentication'
import auth from './auth'
import message from './message'
import mediaQuery from './mediaQuery'
import profile from './profile'

const rootReducer = {
  auth,
  navbar,
  layout,
  message,
  mediaQuery,
  profile,
}

export default rootReducer
